<template>
  <b-overlay
    :show="isLoading"
    rounded
    opacity="0.6"
    spinner-variant="primary"
    spinner-mediam
  >
    <transition
      :name="$store.state.appConfig.layout.routerTransition"
      mode="out-in"
    >
      <b-row>
        <b-col
          md="12"
          sm="12"
        >
          <b-card>
            <table class="table" v-if="Object.keys(results).length">
              <thead>
                <th>{{$t('Name')}}</th>
                <th>{{$t('Count')}}</th>
                <th>{{$t('Total')}}</th>
                <th>{{$t('Invoice Average')}}</th>
              </thead>
              <template v-for="(shopper,i) in results.shoppers">
              <tr :key="i">
                <td>{{shopper.name}}</td>
                <td>{{shopper.count}}</td>
                <td>{{shopper.total_sales}} {{$t('SAR')}}</td>
                <td>{{shopper.avreg}} {{$t('SAR')}}</td>
              </tr>
              </template>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </transition>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  data() {
    return {
      isLoading: false,
      results: {}
    }
  },
  mounted() {
    this.getResult();
  },
  methods: {
    getResult(){
      if (this.$parent.$parent.$parent.from && this.$parent.$parent.$parent.to) {
        this.isLoading = true
        useJwt.post('/reports/shoppers',{
          from: this.$parent.$parent.$parent.from, 
          to: this.$parent.$parent.$parent.to
        })
        .then((response) => {
          // console.log(response.data)
          this.results = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
      }
    }
  },
}
</script>
