<template>
  <b-overlay
    :show="isLoading"
    rounded
    opacity="0.6"
    spinner-variant="primary"
    spinner-mediam
  >
    <transition
      :name="$store.state.appConfig.layout.routerTransition"
      mode="out-in"
    >
      <b-row>
        <b-col
          md="12"
          sm="12"
        >
          <b-card>
            <table class="table" v-if="Object.keys(results).length">
              <thead>
                <th>{{$t('Name')}}</th>
                <th>{{$t('Count')}}</th>
                <th>{{$t('Total')}}</th>
                <th>{{$t('Invoice Average')}}</th>
              </thead>
              <template v-for="(shippingMethod,i) in results.shippingMethods">
              <tr :key="i">
                <td>
                  <b-img
                    :src="`https://cdn.mhd.sa/asset/images/providers/${shippingMethod.name_en}-logo.png`"
                    height="40"
                    class="ml-1 mr-1"
                    />
                  {{shippingMethod.name}}
                </td>
                <td>{{shippingMethod.count}}</td>
                <td>{{shippingMethod.total_sales}} {{$t('SAR')}}</td>
                <td>{{shippingMethod.avreg}} {{$t('SAR')}}</td>
              </tr>
              </template>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </transition>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  data() {
    return {
      isLoading: false,
      results: {}
    }
  },
  mounted() {
    this.getResult();
  },
  methods: {
    getResult(){
      if (this.$parent.$parent.$parent.from && this.$parent.$parent.$parent.to) {
        this.isLoading = true
        useJwt.post('/reports/shipping-methods',{
          from: this.$parent.$parent.$parent.from, 
          to: this.$parent.$parent.$parent.to
        })
        .then((response) => {
          // console.log(response.data)
          this.results = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
      }
    }
  },
}
</script>
