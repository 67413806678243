<template>
  <div>
    <b-row>
      <b-col md="4" offset-md="8">
        <b-card no-body class="mb-1">
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="flatPickrConfig"
            :placeholder="$t('Date range')"
            @on-change="splitDate"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      ref="btabs"
    >

      <!-- Orders tab -->
      <b-tab active lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Orders')}}</span>
        </template>

        <orders-reports ref="ordersReports" />
      </b-tab>
      <!--/ Orders tab -->

      <!-- Products tab -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="CodesandboxIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Products')}}</span>
        </template>

        <products-reports ref="productsReports" />
      </b-tab>
      <!--/ Products tab -->

      <!-- Packages tab -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="PackageIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Packages')}}</span>
        </template>

        <packages-reports ref="packagesReports" />
      </b-tab>
      <!--/ Packages tab -->

      <!-- Payment Methods -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="CreditCardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Payment Methods')}}</span>
        </template>

        <payment-methods-reports ref="paymentMethodsReports" />
      </b-tab>
      
      <!-- Shipping Methods -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="TruckIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Shipping Methods')}}</span>
        </template>

        <shipping-methods-reports ref="shippingMethodsReports" />
      </b-tab>

      <!-- Cities -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="MapPinIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Cities')}}</span>
        </template>

        <cities-reports ref="citiesReports" />
      </b-tab>

      <!-- Shoppers -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Shoppers')}}</span>
        </template>

        <shoppers-reports ref="shoppersReports" />
      </b-tab>

      <!-- Coupons -->
      <b-tab lazy>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="TagIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('Coupons')}}</span>
        </template>

        <coupons-reports ref="couponsReports" />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {Arabic} from 'flatpickr/dist/l10n/ar.js';

import OrdersReports from './OrdersReports.vue'
import ProductsReports from './ProductsReports.vue'
import PackagesReports from './PackagesReports.vue'
import PaymentMethodsReports from './PaymentMethodsReports.vue'
import ShippingMethodsReports from './ShippingMethodsReports.vue'
import CitiesReports from './CitiesReports.vue'
import ShoppersReports from './ShoppersReports.vue'
import CouponsReports from './CouponsReports.vue'

export default {
  components: {
    flatPickr,
    OrdersReports,
    ProductsReports,
    PackagesReports,
    PaymentMethodsReports,
    ShippingMethodsReports,
    CitiesReports,
    ShoppersReports,
    CouponsReports,
  },
  data() {
    return {
      options: {},
      rangeDate: null,
      from: null,
      to: null,
      flatPickrConfig: {
          altFormat: 'j F Y',
          altInput: true,
          dateFormat: 'Y-m-d',
          locale: Arabic,    
          mode: 'range'
      },
    }
  },
  mounted() {
    
  },
  methods: {
    splitDate(){
      if (this.rangeDate !=null && this.rangeDate.includes(' - ')) {
        let arrayDate = this.rangeDate.split(' - ')
        this.from = arrayDate[0]
        this.to   = arrayDate[1]

        // 0 : ordersReports
        if (this.$refs.btabs.currentTab == 0) {
          this.$refs.ordersReports.getResult();
        }

        // 1 : productsReports
        if (this.$refs.btabs.currentTab == 1) {
          this.$refs.productsReports.getResult();
        }

        // 2 : paymentMethodsReports
        if (this.$refs.btabs.currentTab == 2) {
          this.$refs.paymentMethodsReports.getResult();
        }

        // 3 : shippingMethodsReports
        if (this.$refs.btabs.currentTab == 3) {
          this.$refs.shippingMethodsReports.getResult();
        }

        // 4 : citiesReports
        if (this.$refs.btabs.currentTab == 4) {
          this.$refs.citiesReports.getResult();
        }

        // 5 : shoppersReports
        if (this.$refs.btabs.currentTab == 5) {
          this.$refs.shoppersReports.getResult();
        }

        // 6 : couponsReports
        if (this.$refs.btabs.currentTab == 6) {
          this.$refs.couponsReports.getResult();
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>