<template>
  <b-overlay
    :show="isLoading"
    rounded
    opacity="0.6"
    spinner-variant="primary"
    spinner-mediam
  >
    <transition
      :name="$store.state.appConfig.layout.routerTransition"
      mode="out-in"
    >
      <b-row>
        <b-col
          md="12"
          sm="12"
          class="text-center mb-1"
        >
          <!-- <statistic-card-vertical
            color="danger"
            icon="BarChartIcon"
            :statistic="`${results.repeatPurchaseRate || 0} %`"
            :statistic-title="$t('Repeat Purchase Rate')"
          /> -->
            <!-- apex chart -->
            <vue-apex-charts
              type="radialBar"
              height="270"
              :options="repeatPurchaseRateOptions.chartOptions"
              :series="[results.repeatPurchaseRate || 0]"
            />
            {{$t('Repeat Purchase Rate')}}
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="success"
            icon="TrendingUpIcon"
            :statistic="`${results.salesTotal || 0} ${$t('SAR')}`"
            :statistic-title="$t('Sales Total')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="warning"
            icon="LayersIcon"
            :statistic="`${results.ordersCount || 0}`"
            :statistic-title="$t('Orders Count')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="ActivityIcon"
            :statistic="`${results.average || 0} ${$t('SAR')}`"
            :statistic-title="$t('Invoice Average')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="TruckIcon"
            :statistic="`${results.shippingTotal || 0} ${$t('SAR')}`"
            :statistic-title="$t('Total Shipping')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="warning"
            icon="TrendingUpIcon"
            :statistic="`${results.paymentTotal || 0} ${$t('SAR')}`"
            :statistic-title="$t('Total COD')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="success"
            icon="BarChartIcon"
            :statistic="`${results.totalProfit || 0} ${$t('SAR')}`"
            :statistic-title="$t('Profit')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="UsersIcon"
            :statistic="`${results.allShoppers || 0}`"
            :statistic-title="$t('Shoppers Counts')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="UsersIcon"
            :statistic="`${results.newShoppers || 0}`"
            :statistic-title="$t('New Shoppers')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="TrendingUpIcon"
            :statistic="`${results.totalItemsForNewShoppers || 0}`"
            :statistic-title="$t('Total items from new Shoppers')"
          />
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="TrendingUpIcon"
            :statistic="`${results.itemsTotal || 0}`"
            :statistic-title="$t('Total items')"
          />
        </b-col>
        <b-col
          md="12"
          sm="12"
        >
        <b-card>
          <table class="table">
            <thead>
              <th>{{$t('Name')}}</th>
              <th>{{$t('Total')}}</th>
              <th>{{$t('Ad Spent')}}</th>
              <th>ROAS</th>
            </thead>
            <tr>
              <td>{{$t('Total items')}}</td>
              <td>{{results.itemsTotal || 0}}</td>
              <td><input type="text" name="total_items_ad_spent" v-model="total_items_ad_spent" /></td>
              <td>{{(results.itemsTotal / total_items_ad_spent).toFixed(2)}}</td>
            </tr>
            <tr>
              <td>{{$t('Total items from new Shoppers')}}</td>
              <td>{{results.totalItemsForNewShoppers || 0}}</td>
              <td><input type="text" name="total_items_from_new_shoppers_ad_spent" v-model="total_items_from_new_shoppers_ad_spent" /></td>
              <td>{{(results.totalItemsForNewShoppers / total_items_from_new_shoppers_ad_spent).toFixed(2)}}</td>
            </tr>
          </table>
        </b-card>
        </b-col>
      </b-row>
    </transition>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/useJwt'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
export default {
  components: {
    StatisticCardVertical,
    VueApexCharts,
  },
  data() {
    return {
      isLoading: false,
      results: {},
      total_items_ad_spent: 1,
      total_items_from_new_shoppers_ad_spent: 1,
      apexChartKey: Date.now(),
      repeatPurchaseRateOptions: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: -30,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                value: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: ['#6cdef4'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [''],
        },
      },
    }
  },
  mounted() {
    this.getResult();
  },
  methods: {
    getResult(){
      if (this.$parent.$parent.$parent.from && this.$parent.$parent.$parent.to) {
        this.isLoading = true
        useJwt.post('/reports/orders',{
          from: this.$parent.$parent.$parent.from, 
          to: this.$parent.$parent.$parent.to
        })
        .then((response) => {
          // console.log(response.data)
          this.results = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
      }
    }
  },
}
</script>
